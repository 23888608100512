import axios from "axios";
import { Promise } from "core-js";
import userService from '../../helpers/userService';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const state = {
    materialsArr: [],
    id: null,
    name: '',
    address: '',
    phone: '',
    material: null,
};

export const mutations = {
  setMaterials(state,payload){
    state.materialsArr = payload;
  },
  setMaterial(state,payload){
    state.material = payload;
  },
  deleteMaterial(state, id){
    let index = state.materialsArr.findIndex(element => element.id == id)
     state.materialsArr.splice(index, 1)
    },
    clear(state){
      state.materialsArr = [];
    }
}

export const getters = {
  allMaterials(state){
    return state.materialsArr;
  },
  getMaterial(state){
    return state.material;
  },

}

export const actions = {
    async getAllMaterials({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/material/get", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setMaterials',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clear')
            await reject(error);
          });
      });
    },
    async getYearMaterials({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/material/get?year=${payload}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setMaterials',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clear')
            await reject(error);
          });
      });
    },
    async getStudentLectureMaterials({ commit },payload){
      
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/material/get_material_by_lecture/${payload}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setMaterials',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clear')
            await reject(error);
          });
      });
    },
    deleteMaterial({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .delete("/material/delete_material/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteMaterial',payload);
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
}
