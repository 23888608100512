import axios from "axios";
import { Promise } from "core-js";
import userService from '../../helpers/userService';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const state = {
    chances: 0,
    elementsArr: [],
    element: null,
    report:[]
};

export const mutations = {
  setChances(state,payload){
    state.chances = payload;
  },
  setReport(state,payload){
    state.report = payload;
  },
  setElements(state,payload){
    state.elementsArr = payload;
  },
  setElement(state,payload){
    state.element = payload;
  },
  clearElements(state){
    state.elementsArr = [];
  },
  decChances(state){
    state.chances -= 1;
  },
  addChanceNo(state){
    state.chances += 1;
  },
  deleteElementArr(state, id){
    let index = state.elementsArr.findIndex(element => element.id == id)
     state.elementsArr.splice(index, 1)
    },
    clear(state){
      state.chances= 0;
      state.elementsArr= [];
      state.element= null;
      state.report= [];
    }
}

export const getters = {
  allChances(state){
    return state.chances;
  },
  allElements(state){
    return state.elementsArr;
  },
  singleElement(state){
    return state.element;
  },
  report(state){
    return state.report;
  }
}

export const actions = {
    async getFantasyReprot({ commit }, payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("periodic_table/fantasy_report", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            if(response.status == 200){
              commit('setReport',response.data);
            }
            resolve(true);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getMyElements({ commit }, payload){
      await commit('clearElements');
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("periodic_table/student_elements/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            if(response.status == 200){
              commit('setElements',response.data);
            }
            resolve(true);
          })
          .catch(async error => {
            // await commit('clear');
            await reject(error);
          });
      });
    },
    async getMyChances({ commit }, payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("periodic_table/chance/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setChances',response.data.no_of_chances);
            resolve(true);
          })
          .catch(async error => {
            await commit('setChances',0);
            // await commit('clear');
            await reject(error);
          });
      });
    },
    useChance({ commit }, payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("periodic_table/grantee_element/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(async response => {
            //if a response returned
            console.log(response);
            await commit('decChances');
            await commit('setElement',response.data.periodic_table_elements)
            resolve(true);
          })
          .catch(error => {
            commit('setChances',0);
            reject(error);
          });
      });
    },
    addChance({ commit }, payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("periodic_table/chance/" + payload.stud_id, payload ,{headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(async response => {
            //if a response returned
            await commit('addChanceNo');
            resolve(true);
          })
          .catch(error => {
            commit('setChances',0);
            reject(error);
          });
      });
    },
    removeChance({ commit }, payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .delete("periodic_table/revoke_chance/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(async response => {
            //if a response returned
            await commit('decChances');
            resolve(true);
          })
          .catch(error => {
            commit('setChances',0);
            reject(error);
          });
      });
    },
    deleteElement({ commit }, payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .delete(`periodic_table/revoke_element?stud_id=${payload.studID}&element_id=${payload.elementID}` , {} ,{headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(async response => {
            //if a response returned
            commit('deleteElementArr',payload.elementID);
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    async getAllBalanceCodes({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/admin/balances", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setBalanceCodes',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    generate({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post(`/admin/balance/create_codes?numberofcode=${payload.number}`,{balance:payload.amount,center_id:payload.center_id}, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    redeemCode({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post(`/student/balance/charge?code=${payload}`,{}, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
}
