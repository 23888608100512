import axios from "axios";
import { Promise } from "core-js";
import userService from '../../helpers/userService';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const state = {
    quizesArr: [],
    gradesArr: [],
    id: null,
    name: '',
    address: '',
    phone: '',
    quiz: {},
    question: {},
    scores: [],
    count: 0,
};

export const mutations = {
  setQuizes(state,payload){
    state.quizesArr = payload;
  },
  setGrades(state,payload){
    state.gradesArr = payload;
  },
  setScores(state,payload){
    state.scores = payload;
  },
  setQuiz(state,payload){
    state.quiz = payload;
  },
  setQuizCount(state,payload){
    state.count = payload;
  },
  setQuestion(state,payload){
    state.question = payload;
  },
  deleteQuiz(state, id){
    let index = state.quizesArr.findIndex(element => element.id == id)
     state.quizesArr.splice(index, 1)
    },
  deleteScore(state, id){
    let index = state.scores.findIndex(element => element.quiz.id == id)
     state.scores.splice(index, 1)
    },
  deleteQuestionItem(state, id){
    let index = state.quiz.quiz_questions.findIndex(element => element.id == id)
     state.quiz.quiz_questions.splice(index, 1)
  },
  changeChanceState(state, id){
    let index = state.quizesArr.findIndex(element => element.id == id)
    state.quizesArr[index].give_chance = false;
  },
  openChanceState(state, id){
    let index = state.quizesArr.findIndex(element => element.id == id)
    state.quizesArr[index].give_chance = true;
  },
  clear(state){
    state.quizesArr = [];
    state.gradesArr = [];
    state.id= null;
    state.name= '';
    state.address= '';
    state.phone= '';
    state.quiz= {};
    state.scores= [];
    state.question= {};
  }
}

export const getters = {
  allQuizes(state){
    return state.quizesArr;
  },
  allScores(state){
    return state.scores;
  },
  allQuestions(state){
    return state.quiz.quiz_questions;
  },
  getQuiz(state){
    return state.quiz;
  },
  getQuizCount(state){
    return state.count;
  },
  getQuestion(state){
    return state.question;
  },
  getQuizGrades(state){
    return state.gradesArr;
  },

}

export const actions = {
    getAllQuizes({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/quiz/get_all_quizzes/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setQuizes',response.data);
            resolve(true);
          })
          .catch(error => {
            commit('clear')
            reject(error);
          });
      });
    },
    getQuizGradesByStudent({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/quiz/get_grade_report/${payload.grade}/${payload.id}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setGrades',response.data);
            resolve(true);
          })
          .catch(error => {
            commit('clear')
            reject(error);
          });
      });
    },
    async getAllQuizesByLecture({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/quiz/get_quiz_by_lecture/${payload.id}`, {params:{type:payload.type},headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setQuizes',response.data);
            resolve(true);
          })
          .catch(error => {
            commit('clear');
            reject(error);
          });
      });
    },
    async getQuestion({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/quiz/get_question_by_id/${payload}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setQuestion',response.data);
            resolve(response.data);
          })
          .catch(error => {
            commit('clear');
            reject(error);
          });
      });
    },
    async getAllScores({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/quiz/stud_quiz_log", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setScores',response.data);
            resolve(true);
          })
          .catch(error => {
            commit('clear');
            reject(error);
          });
      });
    },
    async getAllScoresProfile({ commit }, payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/quiz/stud_quiz_log/"+payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setScores',response.data);
            resolve(true);
          })
          .catch(error => {
            commit('clear');
            reject(error);
          });
      });
    },
    async getStudentQuiz({ commit }, payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/quiz/stud_get_quiz/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setQuiz',response.data);
            resolve(response.data);
          })
          .catch(error => {
            commit('clear');
            reject(error);
          });
      });
    },
    async getStudentQuizAnswers({ commit }, payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/quiz/get_solve_history_logged_in/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setQuiz',response.data);
            resolve(response.data);
          })
          .catch(error => {
            commit('clear');
            reject(error);
          });
      });
    },
    async getStudentQuizAnswersAdmin({ commit }, payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/quiz/get_solve_history?stud_id=${payload.id}&quiz_id=${payload.quiz}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setQuiz',response.data);
            resolve(response.data);
          })
          .catch(error => {
            commit('clear');
            reject(error);
          });
      });
    },
    // getAllChaptersByYear({ commit },payload){
    //   return new Promise((resolve, reject) => {
    //     //Send get request to get all centers in db
    //     axios
    //       .get(`/syllabus/chapter?year=${payload}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
    //       .then(response => {
    //         //if a response returned
    //         commit('setChapters',response.data);
    //         resolve(true);
    //       })
    //       .catch(error => {
    //         reject(error);
    //       });
    //   });
    // },
    async getQuizInfo({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/quiz/admin_get_quiz/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(async response => {
            //if a response returned
            await commit('setQuiz',response.data);
            resolve(response.data);
          })
          .catch(error => {
            commit('clear');
            reject(error);
          });
      });
    },
    async getQuizCount({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/quiz/no_of_solvers/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(async response => {
            //if a response returned
            await commit('setQuizCount',response.data.no_of_solvers);
            resolve(response.data);
          })
          .catch(error => {
            commit('clear');
            reject(error);
          });
      });
    },
    resolveQuiz({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .post("/quiz/grant_stud_to_solve",{}, {params:payload, headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteScore',payload.quiz_id);
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    deleteQuiz({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .delete("/quiz/delete_quiz/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteQuiz',payload);
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    deleteQuestion({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .delete("/quiz/delete_question/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteQuestionItem',payload);
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    deleteAnswerImage({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .delete(`/quiz/delete_ans_image?id=${payload.quiz}&ans_number=${payload.choice}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteQuestionItem',payload);
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    addQuiz({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/quiz/add" , payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(async response => {
            //if a response returned
            await commit('setQuiz',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addQuestion({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/quiz/add_question" , payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(async response => {
            //if a response returned
            await commit('setQuestion',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    stopChance({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/quiz/stop_giving_chance/" + payload, {} , {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('changeChanceState',payload)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    openChance({commit},payload){
      commit('openChanceState',payload)
    },
    answerQuiz({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post(`/quiz/solve_quiz?failure=${payload.failure}`,payload , {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    answerQuizByID({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/quiz/assign_quiz",payload , {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    editQuiz({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .put("/quiz/update_quiz/" + payload.id,payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setQuiz',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    editQuestion({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .put("/quiz/edit_question",payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setQuiz',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
}
