import axios from "axios";
import { Promise } from "core-js";
import userService from '../../helpers/userService';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const state = {
    sessionsArr: [],
    studentsArr: [],
    id: null,
    name: '',
    address: '',
    phone: '',
    session: null,
    studentQrData : null,
    isValid: null,
};

export const mutations = {
  setSessions(state,payload){
    state.sessionsArr = payload;
  },
  setSession(state,payload){
    state.session = payload;
  },
  setStudents(state,payload){
    state.studentsArr = payload;
  },
  deleteSession(state, id){
    let index = state.sessionsArr.findIndex(element => element.id == id)
     state.sessionsArr.splice(index, 1)
  },
  deleteStudent(state, id){
    let index = state.studentsArr.findIndex(element => element.id == id)
     state.studentsArr.splice(index, 1)
  },
  setStudentQrData(state,payload){
    state.studentQrData = payload;
  },
  setIsValid(state,payload){
    state.isValid = payload;
  },
  clearAll(state){
    state.studentQrData = null
    state.isValid= null
  },
  clearState(state){
    state.sessionsArr= [];
    state.studentsArr= [];
    state.id= null;
    state.name= '';
    state.address= '';
    state.phone= '';
    state.session= null;
    state.isValid= null;
    state.studentQrData = null;
  }
}

export const getters = {
  allSessions(state){
    return state.sessionsArr;
  },
  getSession(state){
    return state.session;
  },
  getStudents(state){
    return state.studentsArr;
  },
  studentData(state){
    return state.studentQrData;
  }
  

}

export const actions = {
    async getAllSessions({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/attendance/attendance_session", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setSessions',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clearState');
            await reject(error);
          });
      });
    },
    async getAllSessionsFilters({ commit }, payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/attendance/filter_attendance_session`, {params:{year:payload.stage,lecture:payload.lecture,center:payload.center},headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setSessions',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clearState');
            await reject(error);
          });
      });
    },
    async getSessionInfo({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/attendance/attendance_session/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setSession',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clearState');
            await reject(error);
          });
      });
    },
    deleteSession({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .delete("/attendance/attendance_session/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteSession',payload);
          resolve(true)
        })
        .catch(error => {
          reject(error)
        });
      });
    },
    deleteStudent({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .delete("/attendance/attendance_delete/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteStudent',payload);
          resolve(true)
        })
        .catch(error => {
          reject(error)
        });
      });
    },
    addSession({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/attendance/attendance_session/add" , payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setSession',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    editSession({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .put("/attendance/attendance_session/" + payload.id,payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setSession',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    editStudentInSession({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .put(`/attendance/stud_att_edit?attendance_id=${payload.id}&session_id=${payload.session_id}`,{}, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setSession',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    linkStudentQrcode({commit},payload){
    return new Promise((resolve, reject) => {
      //Send get request to get all centers in db
      axios
        .post(`/student/qrcode/?qr_rnd_txt=${ payload.qrcode }&phone=${ payload.phone }` ,payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  validateQRcode({commit},payload){
    return new Promise((resolve, reject) => {
      //Send get request to get all centers in db
      axios
        .get(`/attendance/qrcheck?qr_txt=${payload}` , {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          if(response.data){
            commit('setIsValid',true);
          }else{
            commit('setStudentQrData',response.data);
          }
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  phoneValidator({commit},payload){
    return new Promise((resolve, reject) => {
      //Send get request to get all centers in db
      axios
        .get(`/attendance/phonecheck?phone=`+ payload.phone , {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          if(response.data){
            commit('setIsValid',true);
          }else{
            commit('setStudentQrData',response.data);
          }
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  codeValidator({commit},payload){
    return new Promise((resolve, reject) => {
      //Send get request to get all centers in db
      axios
        .get(`/periodic_table/stud_metadata/`+ payload , {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          if(response.data){
            commit('setIsValid',true);
          }else{
            commit('setStudentQrData',response.data);
          }
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  clearData({commit}){
    commit('clearAll')
  },
  takeAttendance({commit},payload){
    return new Promise((resolve, reject) => {
      //Send get request to get all centers in db
      axios
        .post(`/attendance/attendance/add?qr_txt=${payload.qrcode}&attendance_id=${payload.session}&assistant_id=${payload.user_id}` ,{}, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async getSessionStudents({commit},payload){
    return new Promise((resolve, reject) => {
      //Send get request to get all centers in db
      axios
        .get(`/attendance/attendance/${payload}` , {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('setStudents',response.data)
          resolve(true);
        })
        .catch(async error => {
          await commit('clearState');
          await reject(error);
        });
    });
  }
  
}
          