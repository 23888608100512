import axios from "axios";
import { Promise } from "core-js";
import userService from '../../helpers/userService';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const state = {
    videosArr: [],
    id: null,
    lock: null,
    chapter_id: null,
    lesson_id: null,
    token_id: null,
    cost: null,
    video: null,
    credentials:null,
    views: null,
    viewsArr: [],
};

export const mutations = {
  setVideos(state,payload){
    state.videosArr = payload;
  },
  setVideoView(state,payload){
    state.views = payload;
  },
  setVideosViews(state,payload){
    state.viewsArr = payload;
  },
  setVideo(state,payload){
    state.video = payload;
  },
  setCredentials(state,payload){
    state.credentials = payload;
  },
  deleteVideo(state, id){
    let index = state.videosArr.findIndex(element => element.video_id == id)
     state.videosArr.splice(index, 1)
    },
    clear(state){
      state.videosArr= []
      state.id= null
      state.lock= null
      state.chapter_id= null
      state.lesson_id= null
      state.token_id= null
      state.cost= null
      state.video= null
      state.views= null
      state.viewsArr= null
    }
}

export const getters = {
  allVideos(state){
    return state.videosArr;
  },
  allViews(state){
    return state.viewsArr;
  },
  getVideo(state){
    return state.video;
  },
  getViews(state){
    return state.views;
  },
  allCredentials(state){
    return state.credentials;
  },

}

export const actions = {
    async getAllVideos({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/video/get_videos`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setVideos',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getAllVideosViews({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/video_views/get_all_views/${payload}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setVideosViews',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getAllVideosByLecture({ commit }, payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/video/get_videos_by_lecture/` + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setVideos',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getCredentials({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/video/get_credentials`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setCredentials',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getAllYoutubeVideos({ commit },payload){
      await commit('clear');
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/video/youtube/all?year=${payload}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setVideos',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getAllStudentVideos({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/video/students`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setVideos',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getAllStudentYoutubeVideos({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/video/youtube/students`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setVideos',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getVideoInfo({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/video/get_video_by_id/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setVideo',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getVideoViews({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/video_views/get_views/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setVideoView',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getYoutubeVideoInfo({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/video/youtube/student/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setVideo',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    deleteVideo({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .delete("/video/delete_video/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteVideo',payload);
          resolve(true)
        })
        .catch(error => {
          reject(error)
        });
      });
    },
    deleteYoutubeVideo({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .delete("/video/youtube/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteVideo',payload);
          resolve(true)
        })
        .catch(error => {
          reject(error)
        });
      });
    },
    addVideo({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/video/add_video" , payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setVideo',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addCredentials({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/video/add_credentials" , payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addLectureMinutes({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post(`/video_views/add_views/${payload.lecture_id}/${payload.stud_id}` , payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    decMinutes({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post(`/video_views/dec_views/${payload.video_id}/${payload.minutes}/${payload.stud_id}` , payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    incMinutes({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post(`/video_views/inc_views/${payload.video_id}/${payload.minutes}/${payload.stud_id}` , payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addYoutubeVideo({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/video/youtube/form_create" , payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setVideo',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    editVideo({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .put("/video/update_video",payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setVideo',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    editCredentials({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .put("/video/update_bunny_credentials",payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    editYoutubeVideo({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .put("/video/youtube/" + payload.id,payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setVideo',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    buyVideo({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/student/purchase",payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    clearList({commit}){
      commit('clear')
    },
}
