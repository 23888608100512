import axios from "axios";
import { Promise } from "core-js";
import userService from '../../helpers/userService';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const state = {
    homeworksArr: [],
    id: null,
    name: '',
    address: '',
    phone: '',
    homework: {},
    question: {},
    scores: []
};

export const mutations = {
  setHomeworks(state,payload){
    state.homeworksArr = payload;
  },
  setScores(state,payload){
    state.scores = payload;
  },
  setHomework(state,payload){
    state.homework = payload;
  },
  setQuestion(state,payload){
    state.question = payload;
  },
  deleteHomework(state, id){
    let index = state.homeworksArr.findIndex(element => element.id == id)
     state.homeworksArr.splice(index, 1)
    },
  deleteQuestionItem(state, id){
    let index = state.homework.quiz_questions.findIndex(element => element.id == id)
     state.homework.quiz_questions.splice(index, 1)
  },
  changeChanceState(state, id){
    let index = state.homeworksArr.findIndex(element => element.id == id)
     state.homeworksArr[index].give_chance = false;
    },
    openChanceState(state, id){
    let index = state.homeworksArr.findIndex(element => element.id == id)
     state.homeworksArr[index].give_chance = true;
    },
    clear(state){
      state.homeworksArr = [];
      state.id= null;
      state.name= '';
      state.address= '';
      state.phone= '';
      state.homework= {};
      state.scores= [];
      state.question= {};
    }
}

export const getters = {
  allHomeworks(state){
    return state.homeworksArr;
  },
  allScores(state){
    return state.scores;
  },
  allQuestions(state){
    return state.homework.quiz_questions;
  },
  getHomework(state){
    return state.homework;
  },

}

export const actions = {
    async getAllHomeworks({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/quiz/get_all_homeworks", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setHomeworks',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getAllHomeworksByLecture({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/quiz/get_homework_by_lecture/${payload}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setHomeworks',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getQuestion({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/quiz/get_question_by_id/${payload}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setQuestion',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getAllScores({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/quiz/stud_quiz_log", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setScores',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getStudentHomework({ commit }, payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/quiz/stud_get_quiz/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setHomework',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    // getAllChaptersByYear({ commit },payload){
    //   return new Promise((resolve, reject) => {
    //     //Send get request to get all centers in db
    //     axios
    //       .get(`/syllabus/chapter?year=${payload}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
    //       .then(response => {
    //         //if a response returned
    //         commit('setChapters',response.data);
    //         resolve(true);
    //       })
    //       .catch(error => {
    //         reject(error);
    //       });
    //   });
    // },
    async getHomeworkInfo({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/quiz/admin_get_quiz/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(async response => {
            //if a response returned
            await commit('setHomework',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    deleteHomework({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .delete("/quiz/delete_quiz/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteHomework',payload);
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    deleteQuestion({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .delete("/quiz/delete_question/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteQuestionItem',payload);
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    deleteAnswerImage({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .delete(`/quiz/delete_ans_image?id=${payload.quiz}&ans_number=${payload.choice}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteQuestionItem',payload);
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    addHomework({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/quiz/add" , payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(async response => {
            //if a response returned
            await commit('setHomework',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addQuestion({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/quiz/add_question" , payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(async response => {
            //if a response returned
            await commit('setQuestion',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    stopChance({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/quiz/stop_giving_chance/" + payload, {} , {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('changeChanceState',payload)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    openChance({commit},payload){
      commit('openChanceState',payload)
    },
    answerHomework({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/quiz/solve_quiz",payload , {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    takeHomeworkByID({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/quiz/assign_homework",payload , {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // editChapter({commit},payload){
      editHomework({commit},payload){
        return new Promise((resolve, reject) => {
          //Send get request to get all centers in db
          axios
            .put("/quiz/update_quiz/" + payload.id,payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
            .then(response => {
              //if a response returned
              commit('setHomework',response.body)
              resolve(true);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      editQuestion({commit},payload){
        return new Promise((resolve, reject) => {
          //Send get request to get all centers in db
          axios
            .put("/quiz/edit_question",payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
            .then(response => {
              //if a response returned
              commit('setQuiz',response.body)
              resolve(true);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
}
