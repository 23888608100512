import axios from "axios";
import { Promise } from "core-js";
import userService from '../../helpers/userService';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const state = {
    studentsNo: 0,
    assistantsNo: 0,
    centersNo: 0,
    sessionsNo: 0,
    sessionCounter: 0,
    studentCounter: 0,
    studentCounterOnline: 0,
    centersStudents: [],
};

export const mutations = {
  setStudents(state,payload){
    state.studentsNo = payload
  },
  setAssistants(state,payload){
    state.assistantsNo = payload
  },
  setCenters(state,payload){
    state.centersNo = payload
  },
  setSessions(state,payload){
    state.sessionsNo = payload
  },
  setSessionStat(state,payload){
    state.sessionCounter = payload
  },
  setStudentStat(state,payload){
    state.studentCounter = payload
  },
  setStudentStatOnline(state,payload){
    state.studentCounterOnline = payload
  },
  setCentersStudents(state,payload){
    state.centersStudents = {
        labels:[],
        series:[]
    }
    payload.forEach(element => {
        state.centersStudents.labels.push(element.Center.name)
        state.centersStudents.series.push(element['number of students'])
    });
  },
}

export const getters = {
  students(state){
    return state.studentsNo;
  },
  assistants(state){
    return state.assistantsNo;
  },
  centers(state){
    return state.centersNo;
  },
  sessions(state){
    return state.sessionsNo;
  },
  centersStats(state){
    return state.centersStudents;
  },
  sessionCount(state){
    return state.sessionCounter;
  },
  studentCount(state){
    return state.studentCounter;
  },
  studentCountOnline(state){
    return state.studentCounterOnline;
  }

  
}

export const actions = {
    getStudentsStat({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/statistics/all_students", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setStudents',response.data['number of students']);
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getAssistantsStat({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/statistics/all_assistants", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setAssistants',response.data['number of assistants']);
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getCentersStat({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/statistics/all_centers", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setCenters',response.data['number of centers']);
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getSessionsStat({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/statistics/attendance_session", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setSessions',response.data['number of attendance session']);
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getCentersStudentsStat({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/statistics/stud_center", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setCentersStudents',response.data);
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getSessionStat({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/statistics/attendance_session/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setSessionStat',response.data['number of attendance']);
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getStudentStat({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/statistics/stud_attend/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setStudentStat',response.data['This student attend']);
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getStudentStatOnline({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/attendance/online_attendance_by_id/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setStudentStatOnline',response.data.no_of_online_attendance);
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    
}
