import axios from "axios";
import { Promise } from "core-js";
import userService from '../../helpers/userService';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const state = {
    centersArr: [],
    assistantsArr: [],
    yearsArr: [],
    chaptersArr:[],
    sessionsArr: [],
};

export const mutations = {
  setCenters(state,payload){
    state.centersArr = payload;
  },
  setAssistants(state,payload){
    state.assistantsArr = payload;
  },
  setYears(state,payload){
    state.yearsArr = payload;
  },
  setChapters(state,payload){
    state.chaptersArr = payload;
  },
  setSessions(state,payload){
    state.sessionsArr = payload;
  },
  clearChapters(state){
    state.chaptersArr = [];
  },
  clearSessions(state){
    state.sessionsArr = [];
  },
  clearYears(state){
    state.yearsArr = [];
  },
  clearCenters(state){
    state.centersArr = [];
  },
  clearAssistants(state){
    state.assistantsArr = [];
  },
  
}

export const getters = {
  centers(state){
    return state.centersArr;
  },
  assistants(state){
    return state.assistantsArr;
  },
  years(state){
    return state.yearsArr;
  },
  chapters(state){
    return state.chaptersArr;
  },
  sessions(state){
    return state.sessionsArr;
  },
}

export const actions = {
    getCenterOption({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/center/all?dd=dropdown", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setCenters',response.data);
            resolve(true);
          })
          .catch(error => {
            reject(error);
            commit('clearCenters');
          });
      });
    },
    getYearOption({ commit }){
      commit('clearYears');
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/educational_year/all?dd=dropdown", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setYears',response.data);
            resolve(true);
          })
          .catch(error => {
            reject(error);
            commit('clearCenters');
          });
      });
    },
    getAssistantOption({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/assistant/assistant?dd=dropdown", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setAssistants',response.data);
            resolve(true);
          })
          .catch(error => {
            reject(error);
            commit('clearAssitants');
          });
      });
    },
    getChapterOption({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/syllabus/chapter?dd=dropdown", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setChapters',response.data);
            resolve(true);
          })
          .catch(error => {
            reject(error);
            commit('clearChapters');
          });
      });
    },
    getYearChapterOption({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/syllabus/chapter?dd=dropdown&year=${payload}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setChapters',response.data);
            resolve(true);
          })
          .catch(error => {
            reject(error);
            commit('clearChapters');
          });
      });
    },
    getChapterSessionsOption({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/attendance/sessoins_by_lecture_option/${payload}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setSessions',response.data);
            resolve(true);
          })
          .catch(error => {
            reject(error);
            commit('clearSessions');
          });
      });
    },
    clearChaptersOption({commit}){
      commit('clearChapters');
    },
    clearSessionsOption({commit}){
      commit('clearSessions');
    },
}
