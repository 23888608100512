import axios from "axios";
import { Promise } from "core-js";
import userService from '../../helpers/userService';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const state = {
    centersArr: [],
    sessionsArr: [],
    id: null,
    name: '',
    address: '',
    phone: '',
    center: null,
};

export const mutations = {
  setCenters(state,payload){
    state.centersArr = payload;
  },
  setSessions(state,payload){
    state.sessionsArr = payload;
  },
  setCenter(state,payload){
    state.center = payload;
  },
  deleteCenter(state, id){
    let index = state.centersArr.findIndex(element => element.id == id)
     state.centersArr.splice(index, 1)
    },
  clear(state){
    state.centersArr= [];
    state.sessionsArr= [];
    state.id= null;
    state.name= '';
    state.address= '';
    state.phone= '';
    state.center= null;
  }
}

export const getters = {
  allCenters(state){
    return state.centersArr;
  },
  getCenter(state){
    return state.center;
  },
  sessions(state){
    return state.sessionsArr;
  },

}

export const actions = {
    async getAllCenters({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/center/all", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setCenters',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getCenterInfo({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/center/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setCenter',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    deleteCenter({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .delete("/center/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteCenter',payload);
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    addCenter({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/center/register" , payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setCenter',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    editCenter({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .put("/center/" + payload.id,payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setCenter',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    async getCenterSessions({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/statistics/center_attend/${payload}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setSessions',response.data['number of attendance on this center']);
            resolve(true);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
}
