import axios from "axios";
import { Promise } from "core-js";
import userService from '../../helpers/userService';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const state = {
    chaptersArr: [],
    id: null,
    name: '',
    address: '',
    phone: '',
    chapter: null,
    counts: null,
};

export const mutations = {
  async setChapters(state,payload){
    if(userService.methods.userRole() != 1){
      state.chaptersArr = payload;
    }else{
      let chapters = payload;
      // let index = await state.chapters.findIndex(element => element.name == 'Bridge')
      // if(index >= 0){
      // 	await chapters.splice(index, 1)
      // }
      // let index2 = await state.chapters.findIndex(element => element.name == 'bridge2')
      // if(index2 >= 0){
      // 	await chapters.splice(index2, 1)
      // }
      state.chaptersArr = chapters;
    }
  },
  setChapter(state,payload){
    state.chapter = payload;
  },
  setChapterCounts(state,payload){
    state.counts = payload;
  },
  deleteChapter(state, id){
    let index = state.chaptersArr.findIndex(element => element.id == id)
    state.chaptersArr.splice(index, 1)
  },
  changeChanceChapter(state, id){
    let index = state.chaptersArr.findIndex(element => element.id == id)
    state.chaptersArr[index].give_chance = !state.chaptersArr[index].give_chance;
  },
  setFree(state, id){
  let index = state.chaptersArr.findIndex(element => element.id == id)
    state.chaptersArr[index].free = true;
  },
  clear(state){
    state.chaptersArr= [];
    state.id= null;
    state.name= '';
    state.address= '';
    state.phone= '';
    state.chapter= null;
    state.counts= null;
  }
}

export const getters = {
  allChapters(state){
    return state.chaptersArr;
  },
  getChapter(state){
    return state.chapter;
  },
  getChapterCounts(state){
    return state.counts;
  },

}

export const actions = {
    async getAllChapters({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/syllabus/chapter", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setChapters',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getAllChaptersByYear({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/syllabus/chapter?year=${payload}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setChapters',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getChapterInfo({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/syllabus/chapter/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setChapter',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getChapterCounts({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/syllabus/attendance_numbers/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setChapterCounts',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    deleteChapter({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .delete("/syllabus/chapter/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteChapter',payload);
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    chanceChapter({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .put(`/syllabus/edit_chapter_chance/${payload.chance}/${payload.id}`,{}, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('changeChanceChapter',payload.id);
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    addChapter({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/syllabus/add_chapter" , payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setChapter',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    purchaseLecture({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/purchase/purchase_lecture/" + payload, {} , {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setFree',payload)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    editChapter({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .put("/syllabus/update_chapter/" + payload.id,payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setChapter',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
}
