import axios from "axios";
import { Promise } from "core-js";
import userService from '../../helpers/userService';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const state = {
    username: "",
    name: "",
    role: null, // 0 for admin , 1 for student , 2 for assistant
    phone_number: "",
    phone_no_1: "",
    phone_no_2: "",
    school: "",
    year: null,
    center: "",
    status: false,
    access_token: null,
    token_type: null,
    isLoggedIn: false,
    id:null,
    update_data: null,
    attendenceArr: [],
    syllabus: null
};

export const mutations = {
  setUserData(state,payload){
    state.username = payload.user.user_name;
    state.name = payload.user.name;
    state.role = payload.user.role;
    state.phone_number = payload.user.phone_number;
    state.id = payload.user.id;
    state.access_token = payload.access_token;
    state.token_type = payload.token_type;
    state.update_data = payload.update_data;
    state.isLoggedIn = true;
  },
  setStudentData(state,payload){
    state.school = payload.school;
    state.status = payload.status;
    state.year = payload.year;
    state.phone_no_1 = payload.phone_no_1;
    state.phone_no_2 = payload.phone_no_2;
  },
  setStore(state){
    state.username = localStorage.getItem("username");
    state.name = localStorage.getItem("name");
    state.role = localStorage.getItem("role");
    state.phone_number = localStorage.getItem("phone_number");
    state.id = localStorage.getItem("userID");
    state.access_token = localStorage.getItem("access_token");
    state.token_type = localStorage.getItem("token_type");
    state.isLoggedIn = localStorage.getItem("isLoggedIn");
    state.year = localStorage.getItem("year");
    state.status = localStorage.getItem("status");
    state.school = localStorage.getItem("school");
    state.phone_no_1 = localStorage.getItem("phone_no_1");
    state.phone_no_2 = localStorage.getItem("phone_no_2");
  },
  setUserAttendence(state,payload){
    state.attendenceArr = payload;
  },
  logout(state){
    localStorage.clear();
    state.username = "";
    state.name = "";
    state.role = null;
    state.phone_number = "";
    state.id = null;
    state.access_token = null;
    state.token_type = null;
    state.isLoggedIn = null;
  },
  setSyllabus(state,payload){
    state.syllabus = payload
  }
}

export const getters = {
  isLoggedIn(state){
    return !!state.isLoggedIn;
  },
  userName(state){
    return state.username;
  },
  fullName(state){
    return state.name;
  },
  role(state){
    return state.role;
  },
  userID(state){
    return state.id;
  },
  myAttendence(state){
    return state.attendenceArr;
  },
  year(state){
    return state.year;
  },
  mySyllabus(state){
    return state.syllabus;
  },

}

export const actions = {
    registerUserStudent({state},payload){
      return new Promise((resolve, reject) => {
        //send a post request with the user data to the database
        axios
          .post("/student/online_student_register_one_shot", payload)
          .then(response => {
            //if a response returned
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    nextStepRegisteration({state},payload){
      return new Promise((resolve, reject) => {
        //send a post request with the user data to the database
        axios
          .post("/student/update_data/"+payload.id, payload)
          .then(response => {
            //if a response returned
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    async loginUser({state,commit},payload){
      let form_data = new FormData();
      for( let key in payload){
        await form_data.append(key,payload[key]);
      }
      return await new Promise((resolve, reject) => {
        axios
          .post("/user/login", form_data)
          .then( async response => {
            //if the status code shows a successful request
            if (response.status == 200 && response.data.login == 'success') {
              //store the user data in the store
              await commit("setUserData", response.data);
              localStorage.setItem("access_token", response.data.access_token);
              //store the frequently used user data
              localStorage.setItem("username", response.data.user.user_name);
              localStorage.setItem("name", response.data.user.name);
              localStorage.setItem("image", response.data.user.image?response.data.user.image:false);
              localStorage.setItem("userID", response.data.user.id);
              localStorage.setItem("role", response.data.user.role);
              localStorage.setItem("token_type", response.data.token_type);
              localStorage.setItem("phone_number", response.data.user.phone_number);
              localStorage.setItem("update_data", response.data.user.update_data);
              localStorage.setItem("isLoggedIn", true);
              if(response.data.user.role == 1){
                await axios.get(`/student/${response.data.user.id}`,{headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}}).
                then(async res=>{
                  localStorage.setItem("school",res.data.school);
                  localStorage.setItem("year",res.data.year);
                  localStorage.setItem("status",res.data.status);
                  localStorage.setItem("phone_no_1",res.data.phone_no_1);
                  localStorage.setItem("phone_no_2",res.data.phone_no_2);
                  await commit("setStudentData", response.data);
                });
              }

            }
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
        });
    },
    async restoreData({commit}){
      return new Promise(async(resolve, reject) => {
        await commit('setStore')
        resolve(true);
      });
    },
    async logoutUser({state,commit},payload){
      return await new Promise((resolve, reject) => {
        axios
          .post("/user/logout",{}, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then( async response => {
                await commit('logout');
                resolve(true);
          }).catch(err =>{
            reject(err);
          })
      });
    },
    updateCredentials({state,commit},payload){
      return  new Promise((resolve, reject) => {
        axios
          .post("/student/update_credentials/",payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then( async response => {
            resolve(true);
          }).catch(err =>{
            reject(err);
          })
      });
    },
    getProfile({commit},payload){
      return new Promise( (resolve, reject) => {
        axios
          .get("/user/profile", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if the status code shows a successful request
            if (response.status == 200) {
              //store the user data in the store
              //commit("setUserData", response.data);
              resolve(response.data);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    profileAttendance({commit},payload){
      return new Promise( async (resolve, reject) => {
        axios
          .get("/user/attendance", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(async response => {
            //if the status code shows a successful request
            if (response.status == 200) {
              //store the user data in the store
              await commit("setUserAttendence", response.data);
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    mySyllabus({commit},payload){
      return new Promise( async (resolve, reject) => {
        axios
          .get("/syllabus/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(async response => {
            //if the status code shows a successful request
            if (response.status == 200) {
              //store the user data in the store
              await commit("setSyllabus", response.data);
              resolve(response.data);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
}
