import axios from "axios";
import { Promise } from "core-js";
import userService from '@/helpers/userService';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const state = {
    postsArr: [],
    slidesArr: [],
    id: null,
    post: null,
    slide: null,
};

export const mutations = {
  setPosts(state,payload){
    state.postsArr = payload;
  },
  setSlides(state,payload){
    state.slidesArr = payload;
  },
  setPost(state,payload){
    state.post = payload;
  },
  setSlide(state,payload){
    state.slide = payload;
  },
  deletePost(state, id){
    let index = state.postsArr.findIndex(element => element.id == id)
     state.postsArr.splice(index, 1)
  },
  deleteSlide(state, id){
    let index = state.slidesArr.findIndex(element => element.id == id)
     state.slidesArr.splice(index, 1)
  },
  clearPostsList(state){
    state.postsArr = [];
  },
  clearSlidesList(state){
    state.slidesArr = [];
  },
}

export const getters = {
  allPosts(state){
    return state.postsArr;
  },
  allSlides(state){
    return state.slidesArr;
  },
  getPost(state){
    return state.post;
  },
  getSlide(state){
    return state.slide;
  },
}

export const actions = {
    // getStudentInfo({ commit },payload){
    //   return new Promise((resolve, reject) => {
    //     //Send get request to get all centers in db
    //     axios
    //       .get("/student/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
    //       .then(response => {
    //         //if a response returned
    //         commit('setStudent',response.data);
    //         resolve(response.data);
    //       })
    //       .catch(error => {
    //         reject(error);
    //       });
    //   });
    // },
    getPostsList({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/landing_page/post/all", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setPosts',response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getSlidesList({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/landing_page/slider/all", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setSlides',response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    async clearPostList({commit}){
      await commit('clearPostsList');
    },
    async clearSlideList({commit}){
      await commit('clearSlidesList');
    },
    deletePost({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .delete("/landing_page/post/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deletePost',payload);
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    deleteSlide({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .delete("/landing_page/slider/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteSlide',payload);
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    // addCenter({commit},payload){
    //   return new Promise((resolve, reject) => {
    //     //Send get request to get all centers in db
    //     axios
    //       .post("/center/register" , payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
    //       .then(response => {
    //         //if a response returned
    //         commit('setCenter',response.body)
    //         resolve(true);
    //       })
    //       .catch(error => {
    //         reject(error);
    //       });
    //   });
    // },
    // editCenter({commit},payload){
    //   return new Promise((resolve, reject) => {
    //     //Send get request to get all centers in db
    //     axios
    //       .put("/center/" + payload.id,payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
    //       .then(response => {
    //         //if a response returned
    //         commit('setCenter',response.body)
    //         resolve(true);
    //       })
    //       .catch(error => {
    //         reject(error);
    //       });
    //   });
    // }
    
}
