import store from '@/state/store'
import userService from '@/helpers/userService'

export default [
    // {
    //     path: '/',
    //     name: 'landing',
    //     component: () => import('../views/pages/extras/coming-soon'),
    //     meta: {
    //         authRequired: false,
    //     },
    // },
    {
        path: '/',
        name: 'landing',
        component: () => import('../views/pages/home/test'),
        meta: {
            authRequired: false,
        },
    },
    {
        path: '/testing',
        name: 'test',
        component: () => import('../views/pages/testing'),
        meta: {
            authRequired: false,
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (userService.methods.isLoggedIn()) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (userService.methods.isLoggedIn()) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    // {
    //     path: '/register/:id',
    //     name: 'registerIndexed',
    //     component: () => import('../views/pages/account/register'),
    //     meta: {
    //         authRequired: true,
    //         authRoles: [1]
    //     },
    // },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['user/isloggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'landing' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'landing' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/home',
        name: 'home',
        meta: {
            authRequired: true,
            authRoles: [0,1,2,3],
        },
        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/me',
        name: 'profileUser',
        meta: {
            authRequired: true,
            authRoles: [0,1,2,3],
        },
        component: () => import('../views/pages/account/profile')
    },
    {
        path: '/me/uploadPicture',
        name: 'profileUploadPicture',
        meta: {
            authRequired: true,
            authRoles: [0,1,2,3],
        },
        component: () => import('../views/pages/account/upload')
    },
    {
        path: '/changeCredentials',
        name: 'profileUpdateCredentials',
        meta: {
            authRequired: true,
            authRoles: [0,1,2,3],
        },
        component: () => import('../views/pages/account/changeCredentials')
    },
    {
        path: '/myattendance',
        name: 'attendaceUser',
        meta: {
            authRequired: true,
            authRoles: [1],
        },
        component: () => import('../views/pages/account/myAttendance')
    },
    {
        path: '/my-syllabus',
        name: 'contentUser',
        meta: {
            authRequired: true,
            authRoles: [1],
        },
        component: () => import('../views/pages/account/mySyllabus')
    },
    {
        path: '/centers/',
        name: 'centerList',
        meta: {
            authRequired: true,
            authRoles: [0,1,2,3],
        },
        component: () => import('../views/pages/centers/index')
    },
    {
        path: '/centers/:center/details',
        name: 'centerDetails',
        meta: {
            authRequired: true,
            authRoles: [0,1,2,3],
        },
        component: () => import('../views/pages/centers/details')
    },
    {
        path: '/centers/:center/sessions',
        name: 'centerSessions',
        meta: {
            authRequired: true,
            authRoles: [0,1,2,3],
        },
        component: () => import('../views/pages/centers/sessions')
    },
    {
        path: '/centers/:id/edit',
        name: 'centerEdit',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/centers/createEditForm')
    },
    {
        path: '/centers/create',
        name: 'centerCreate',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/centers/createEditForm')
    },
    {
        path: '/years/',
        name: 'yearList',
        meta: {
            authRequired: true,
            authRoles: [0,1,2,3],
        },
        component: () => import('../views/pages/years/index')
    },
    {
        path: '/years/:year/details',
        name: 'yearDetails',
        meta: {
            authRequired: true,
            authRoles: [0,1,2,3],
        },
        component: () => import('../views/pages/years/details')
    },
    {
        path: '/years/:year/chapters',
        name: 'yearChapters',
        meta: {
            authRequired: true,
            authRoles: [0,1,2,3],
        },
        component: () => import('../views/pages/years/chaptersInYear')
    },
    {
        path: '/years/:year/videos',
        name: 'yearVideos',
        meta: {
            authRequired: true,
            authRoles: [0,1,2,3],
        },
        component: () => import('../views/pages/years/videosInYear')
    },
    {
        path: '/years/:year/youtube-videos',
        name: 'yearYoutubeVideos',
        meta: {
            authRequired: true,
            authRoles: [0,1,2,3],
        },
        component: () => import('../views/pages/years/youtubeVideosInYear')
    },
    {
        path: '/years/:id/edit',
        name: 'yearEdit',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/years/createEditForm')
    },
    {
        path: '/years/create',
        name: 'yearCreate',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/years/createEditForm')
    },
    {
        path: '/assistants/',
        name: 'assistantList',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/assistants/index')
    },
    {
        path: '/assistants/:assistant/details',
        name: 'assistantDetails',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/assistants/details')
    },
    {
        path: '/assistants/:id/edit',
        name: 'assistantEdit',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/assistants/createEditForm')
    },
    {
        path: '/assistants/create',
        name: 'assistantCreate',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/assistants/createEditForm')
    },
    {
        path: '/announcements/',
        name: 'announcementList',
        meta: {
            authRequired: true,
            authRoles: [0,1],
        },
        component: () => import('../views/pages/announcements/index')
    },
    {
        path: '/announcements/create',
        name: 'announcementCreate',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/announcements/createEditForm')
    },
    {
        path: '/announcements/:id/edit',
        name: 'announcementEdit',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/announcements/createEditForm')
    },
    {
        path: '/posts/posts',
        name: 'postsList',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/posts/index')
    },
    {
        path: '/posts/slides',
        name: 'slidesList',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/slides/index')
    },
    {
        path: '/posts/posts/create',
        name: 'postsEdits',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/posts/createEditForm')
    },
    {
        path: '/posts/slides/create',
        name: 'slidesEdits',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/slides/createEditForm')
    },
    {
        path: '/posts/posts/create/:title/:heading/:sub_heading/:link',
        name: 'postsEditsUpload',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/posts/upload')
    },
    {
        path: '/posts/slides/create/:heading/:sub_heading/:description',
        name: 'slidesEditsUpload',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/slides/upload')
    },
    {
        path: '/sessions/',
        name: 'sessionList',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/sessions/index')
    },
    {
        path: '/sessions/:session/details',
        name: 'sessionDetails',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/sessions/details')
    },
    {
        path: '/sessions/:session/takeAttendance',
        name: 'takeAttendance',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/sessions/takeAttendance')
    },
    {
        path: '/sessions/:session/:attendance/edit',
        name: 'takeAttendance',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/sessions/attendanceEditForm')
    },
    {
        path: '/sessions/:session/students',
        name: 'attendedStudents',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/sessions/studentsInSession')
    },
    {
        path: '/sessions/create',
        name: 'sessionCreate',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/sessions/createEditForm')
    },
    {
        path: '/sessions/:id/edit',
        name: 'sessionEdit',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/sessions/createEditForm')
    },
    {
        path: '/link',
        name: 'linkStudent',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/sessions/linkStudents')
    },
    {
        path: '/students/:student/details',
        name: 'studentDetails',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/students/details')
    },
    {
        path: '/students/:student/showAnswer/:quiz',
        name: 'studentAnswers',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/quizes/studentQuizAnswers')
    },
    {
        path: '/students/:student/attendance',
        name: 'studentAttendance',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/students/attendance')
    },
    {
        path: '/students/:student/fantasy',
        name: 'studentFantasy',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/students/fantasy')
    },
    {
        path: '/students/:student/balance',
        name: 'studentBalance',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/students/balance')
    },
    {
        path: '/students/:student/purchase',
        name: 'studentPurchase',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/students/purchase')
    },
    {
        path: '/students/:student/scores',
        name: 'studentScores',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/students/scores')
    },
    {
        path: '/students/:student/views',
        name: 'studentViews',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/students/videosView')
    },
    {
        path: '/students/approval',
        name: 'studentApproveList',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/students/needApproval')
    },
    {
        path: '/students/all',
        name: 'studentList',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/students/index')
    },
    {
        path: '/students/search-name',
        name: 'studentSearchName',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/students/searchByName.vue')
    },
    {
        path: '/students/no-profile',
        name: 'studentNoProfileList',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/students/noProfileList')
    },
    {
        path: '/students/search',
        name: 'studentSearch',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/students/searchByPhone')
    },
    {
        path: '/students/updateCredentials',
        name: 'studentUpdateCredentials',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/students/updateCredentials')
    },
    {
        path: '/students/:student/updateCredentials',
        name: 'studentUpdateCredentialsProfile',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/students/updateCredentialsProfile')
    },
    {
        path: '/qrcodes',
        name: 'qrcodes',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/qrcodes/index')
    },
    {
        path: '/qrcodes/create',
        name: 'qrcodes-generate',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/qrcodes/createForm')
    },
    {
        path: '/balance-codes/',
        name: 'balance-codes',
        meta: {
            authRequired: true,
            authRoles: [0,3],
        },
        component: () => import('../views/pages/balance/index')
    },
    {
        path: '/balance-codes/create',
        name: 'balance-codes-generate',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/balance/createForm')
    },
    {
        path: '/balance-codes/centers',
        name: 'balance-centers',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/balance/centers')
    },
    {
        path: '/balance-codes/stats',
        name: 'balance-stats',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/balance/stats')
    },
    {
        path: '/balance-codes/centers/create',
        name: 'balance-centers-create',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/balance/createCenterForm')
    },
    {
        path: '/balance-codes/check-code',
        name: 'codes-check',
        meta: {
            authRequired: true,
            authRoles: [0,3],
        },
        component: () => import('../views/pages/balance/checkCode')
    },
    {
        path: '/chapters',
        name: 'chapters',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/chapters/index')
    },
    {
        path: '/students/lectures',
        name: 'lectures',
        meta: {
            authRequired: true,
            authRoles: [1],
        },
        component: () => import('../views/pages/chapters/studentLectures')
    },
    {
        path: '/students/lectures/:lecture',
        name: 'lectureContent',
        meta: {
            authRequired: true,
            authRoles: [1],
        },
        component: () => import('../views/pages/chapters/content')
    },
    {
        path: '/chapters/:chapter/details',
        name: 'chapterDetails',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/chapters/details')
    },
    {
        path: '/chapters/:chapter/takeHomework',
        name: 'chapterHomework',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/chapters/takeHomework')
    },
    {
        path: '/chapters/:chapter/assignQuiz',
        name: 'chapterQuizByID',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/chapters/assignQuiz')
    },
    {
        path: '/chapters/create',
        name: 'chaptersCreate',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/chapters/createEditForm')
    },
    {
        path: '/chapters/:id/edit',
        name: 'chaptersEdit',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/chapters/createEditForm')
    },
    {
        path: '/videos/my-videos',
        name: 'studentVideos',
        meta: {
            authRequired: true,
            authRoles: [1],
        },
        component: () => import('../views/pages/videos/studentVideos')
    },
    {
        path: '/videos/my-sessions',
        name: 'studentYoutubeVideos',
        meta: {
            authRequired: true,
            authRoles: [1],
        },
        component: () => import('../views/pages/videos/youtube/studentVideos')
    },
    {
        path: '/videos/my-sessions/:id',
        name: 'studentYoutubeVideosView',
        meta: {
            authRequired: true,
            authRoles: [1],
        },
        component: () => import('../views/pages/videos/youtube/youtubeVideo')
    },
    {
        path: '/videos/',
        name: 'videosList',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/videos/index')
    },
    {
        path: '/videos/credentials',
        name: 'videosCredentials',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/videos/credentials')
    },
    {
        path: '/videos/lectureMinutes',
        name: 'lectureMinutesAdded',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/videos/openMinutes')
    },
    {
        path: '/videos/credentials/:id/update',
        name: 'videosCredentialsUpdate',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/videos/credentialsForm')
    },
    {
        path: '/videos/credentials/create',
        name: 'videosCredentialsCreate',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/videos/credentialsForm')
    },
    {
        path: '/videos/create',
        name: 'videosCreate',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/videos/createEditForm')
    },
    {
        path: '/videos/:id/edit',
        name: 'videoEdit',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/videos/createEditForm')
    },
    {
        path: '/youtube-videos/create',
        name: 'youtubeVideosCreate',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/videos/youtube/createEditForm')
    },
    {
        path: '/youtube-videos/:id/edit',
        name: 'youtubeVideoEdit',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/videos/youtube/createEditForm')
    },
    {
        path: '/quizes',
        name: 'quizesList',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/quizes/index')
    },
    {
        path: '/exams',
        name: 'examsList',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/quizes/examIndex')
    },
    {
        path: '/trails',
        name: 'trailsList',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/quizes/trialIndex')
    },
    {
        path: '/homeworks',
        name: 'homeworksList',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/homework/index')
    },
    {
        path: '/students/scores',
        name: 'studentScores',
        meta: {
            authRequired: true,
            authRoles: [1],
        },
        component: () => import('../views/pages/quizes/scores')
    },
    {
        path: '/quizes/create/:type',
        name: 'quizCreate',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/quizes/createEditForm')
    },
    {
        path: '/quizes/:id/edit',
        name: 'quizEdit',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/quizes/createEditForm')
    },
    {
        path: '/quizes/:id/edit/:type',
        name: 'quizEdit',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/quizes/createEditForm')
    },
    {
        path: '/homeworks/:id/edit',
        name: 'homeworkEdit',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/homework/createEditForm')
    },
    {
        path: '/homeworks/create',
        name: 'homeworksCreate',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/homework/createEditForm')
    },
    {
        path: '/quizes/:quiz/details',
        name: 'quizDetails',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/quizes/details')
    },
    {
        path: '/quizes/:quiz/studentGrades',
        name: 'quizGradesDetails',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/quizes/studentsByGrade')
    },
    {
        path: '/homeworks/:quiz/details',
        name: 'homeworkDetails',
        meta: {
            authRequired: true,
            authRoles: [0,2,3],
        },
        component: () => import('../views/pages/homework/details')
    },
    {
        path: '/quizes/:quiz/questions/create',
        name: 'questionCreate',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/quizes/questions/createEditForm')
    },
    {
        path: '/quizes/:quiz/questions/:id/edit',
        name: 'questionEdit',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/quizes/questions/createEditForm')
    },
    {
        path: '/homeworks/:quiz/questions/:id/edit',
        name: 'questionHwEdit',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/homework/questions/createEditForm')
    },
    {
        path: '/homeworks/:quiz/questions/create',
        name: 'homeworksQuestionCreate',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/homework/questions/createEditForm')
    },
    {
        path: '/quizes/:quiz/questions/:question/uploadImage',
        name: 'questionUploadImage',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/quizes/questions/questionImage')
    },
    {
        path: '/homeworks/:quiz/questions/:question/uploadImage',
        name: 'homeworkQuestionUploadImage',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/homework/questions/questionImage')
    },
    {
        path: '/quizes/:quiz/questions/:question/uploadAnswerImage',
        name: 'answerUploadImage',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/quizes/questions/answerImage')
    },
    {
        path: '/homeworks/:quiz/questions/:question/uploadAnswerImage',
        name: 'homeworkAnswerUploadImage',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/homework/questions/answerImage')
    },
    {
        path: '/quizes/:quiz/questions/:question/deleteAnswerImage',
        name: 'answerDeleteImage',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/quizes/questions/answerImageDeleter')
    },
    {
        path: '/homeworks/:quiz/questions/:question/deleteAnswerImage',
        name: 'homeworkAnswerDeleteImage',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/homework/questions/answerImageDeleter')
    },
    {
        path: '/quizes/:quiz/showAnswer',
        name: 'studentShowAnswer',
        meta: {
            authRequired: true,
            authRoles: [1],
        },
        component: () => import('../views/pages/quizes/studentQuizAnswers')
    },
    {
        path: '/quizes/:quiz/takeQuiz',
        name: 'studentTakeQuiz',
        meta: {
            authRequired: true,
            authRoles: [1],
        },
        component: () => import('../views/pages/quizes/studentQuiz')
    },
    {
        path: '/homeworks/:quiz/takeHomework',
        name: 'studentTakeHomework',
        meta: {
            authRequired: true,
            authRoles: [1],
        },
        component: () => import('../views/pages/homework/studentQuiz')
    },
    {
        path: '/materials',
        name: 'materialsList',
        meta: {
            authRequired: true,
            authRoles: [0,1],
        },
        component: () => import('../views/pages/materials/index')
    },
    {
        path: '/materials/create',
        name: 'materialCreate',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/materials/createEditForm')
    },
    {
        path: '/materials/upload/:chapter_id/',
        name: 'materialCreateUpload',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/materials/upload')
    },
    {
        path: '/redeem-code',
        name: 'redeemCode',
        meta: {
            authRequired: true,
            authRoles: [1],
        },
        component: () => import('../views/pages/balance/redeemCode')
    },
    {
        path: '/students/lectures/:lecture/:video_id/play',
        name: 'playVideo',
        meta: {
            authRequired: true,
            authRoles: [0,1],
        },
        component: () => import('../views/pages/videos/playVideo')
    },
    {
        path: '/my-fantasy',
        name: 'Fantasy',
        meta: {
            authRequired: true,
            authRoles: [0,1,2,3],
        },
        component: () => import('../views/pages/fantasy/index')
    },
    {
        path: '/reports',
        name: 'Reports',
        meta: {
            authRequired: true,
            authRoles: [0],
        },
        component: () => import('../views/pages/reporting/index')
    },
    {
        path: '*',
        name: 'error404',
        component: () => import('../views/pages/error/404-alt.vue')
    },

]