export default {
    methods: {
      /**
       * This function is used to check if the user is logged in or not where:
       * The function returns true if there is a token stored either in the localStorage or in the sessionStorage
       * The function returns false if there is no token stored in neither the localStoage nor the sessionStorage
       * @public
       */
      isLoggedIn() {
        if (
          localStorage.getItem("access_token") == null &&
          sessionStorage.getItem("access_token") == null
        ) {
          return false;
        } else {
          return true;
        }
      },
      userToken(){
        if(this.isLoggedIn()){
          return localStorage.getItem('access_token');
        }else{
          return false;
        }
      },
      userRole(){
        if(this.isLoggedIn()){
          return parseInt(localStorage.getItem('role'));
        }else{
          return false;
        }
      },
      userYear(){
        if(this.isLoggedIn()){
          return parseInt(localStorage.getItem('year'));
        }else{
          return false;
        }
      },
      userUsername(){
        if(this.isLoggedIn()){
          return localStorage.getItem('username');
        }else{
          return false;
        }
      },
      userName(){
        if(this.isLoggedIn()){
          return localStorage.getItem('name');
        }else{
          return false;
        }
      },
      userImage(){
        if(this.isLoggedIn()){
          return localStorage.getItem('image');
        }else{
          return false;
        }
      },
      userID(){
        if(this.isLoggedIn()){
          return localStorage.getItem('userID');
        }else{
          return false;
        }
      },
    }
  };
  