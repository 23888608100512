import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import simplebar from "simplebar-vue";
import VueTour from 'vue-tour'
import vco from "v-click-outside"
import i18n from './i18n'
import axios from "axios";


import router from './router'
import store from '@/state/store'

import VueApexCharts from 'vue-apexcharts'
import VueStringFilter from 'vue-string-filter'
import Lightbox from 'vue-easy-lightbox'

Vue.config.productionTip = false


//configure axios base url
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Accept'] = '*/*';
axios.defaults.proxy = { target: process.env.BASE_URL, ws:false }

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    const originalRequest = error.config;
    if (response) {
      if (response.status === 500) {
        // router.push('/page/500');
      } 
      else if ( response.status === 401){
        localStorage.clear();
        router.push('/login')
      } 
      else if ( response.status === 403){
        // router.push('/page/403')
      } 
      else if ( response.status === 404){
        // router.push('/page/404')
      } 
      else {
        // notification.error({
        //   message: response.data.message
        // });
        // if(response.data.error){
        //   Object.keys(response.data.error).forEach( key =>{
        //     notification.error({
        //       message: key + ' : ' + response.data.error[key]
        //     });
        //   })
        // }
        return originalRequest;
      }
    }
    return Promise.reject(error);
  },
);


Vue.use(vco)

// As a plugin
import VueMask from 'v-mask'
import VueQuillEditor from 'vue-quill-editor'
import VueDraggable from "vue-draggable";
Vue.use(VueDraggable);
Vue.use(VueQuillEditor)
Vue.use(VueMask);

import VueSlideBar from 'vue-slide-bar'
 
Vue.component('VueSlideBar', VueSlideBar)

Vue.component('apexchart', VueApexCharts)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(require('vue-chartist'))
Vue.component('simplebar', simplebar)
Vue.use(VueStringFilter)
Vue.use(VueTour)
Vue.use(Lightbox)

import "@/assets/scss/app.scss";


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
