import axios from "axios";
import { Promise } from "core-js";
import userService from '../../helpers/userService';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const state = {
    yearsArr: [],
    id: null,
    number: '',
    description: '',
    year: null,
    chaptersArr :[],
};

export const mutations = {
  setYears(state,payload){
    state.yearsArr = payload;
  },
  setYearChapters(state,payload){
    state.chaptersArr = payload;
  },
  setYear(state,payload){
    state.year = payload;
  },
  deleteYear(state, id){
    let index = state.yearsArr.findIndex(element => element.id == id)
     state.yearsArr.splice(index, 1)
    },
    clearState(state){
      state.yearsArr= [];
      state.id= null;
      state.number= '';
      state.description= '';
      state.year= null;
      state.chaptersArr =[];
    }
}

export const getters = {
  allYears(state){
    return state.yearsArr;
  },
  getYear(state){
    return state.year;
  },
  getYearChapters(state){
    return state.chaptersArr;
  },
  

}

export const actions = {
    async getAllYears({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all years in db
        axios
          .get("/educational_year/all", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setYears',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clearState');
            await reject(error);
          });
      });
    },
    async getYearInfo({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all years in db
        axios
          .get("/educational_year/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setYear',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clearState');
            await reject(error);
          });
      });
    },
    async getYearChapters({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all years in db
        axios
          .get(`/syllabus/chapter?year=${payload}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setYearChapters',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clearState');
            await reject(error);
          });
      });
    },
    deleteYear({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .delete("/educational_year/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteYear',payload);
          resolve(true);
        })
        .catch(error => {
          reject(error)
        });
      });
    },
    addYear({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all years in db
        axios
          .post("/educational_year/add" , payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setYear',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    editYear({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all years in db
        axios
          .put("/educational_year/" + payload.id,payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setYear',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
    
}
