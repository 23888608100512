import axios from "axios";
import { Promise } from "core-js";
import userService from '../../helpers/userService';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const state = {
    qrcodesArr: [],
};

export const mutations = {
  setQrcodes(state,payload){
    state.qrcodesArr = payload;
  },
//   deleteCenter(state, id){
//     let index = state.centersArr.findIndex(element => element.id == id)
//      state.centersArr.splice(index, 1)
//     },
}

export const getters = {
  allQrcodes(state){
    return state.qrcodesArr;
  },
}

export const actions = {
    getAllQrcodes({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/admin/qrcode", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setQrcodes',response.data);
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    generate({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/admin/qrcode/generator/"+payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    downloadQrcodes({state},payload){
        return new Promise((resolve, reject) => {
            //Send get request to get all centers in db
            axios
              .get(`/admin/qrcode/download?time=${payload}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
              .then(response => {
                //if a response returned
                resolve(response);
              })
              .catch(error => {
                reject(error);
              });
          }); 
    }
}
