import axios from "axios";
import { Promise } from "core-js";
import userService from '../../helpers/userService';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const state = {
    announcementsArr: [],
    studentsArr: [],
    id: null,
    name: '',
    address: '',
    phone: '',
    announcement: null,
    studentQrData : null,
    isValid: null,
};

export const mutations = {
  setAnnouncements(state,payload){
    state.announcementsArr = payload;
  },
  setAnnouncement(state,payload){
    state.announcement = payload;
  },
  deleteAnnouncement(state, id){
    let index = state.announcementsArr.findIndex(element => element.id == id)
     state.announcementsArr.splice(index, 1)
  },
}

export const getters = {
  allAnnouncements(state){
    return state.announcementsArr;
  },
  getAnnouncement(state){
    return state.announcement;
  },
}

export const actions = {
    getAllAnnouncements({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/announcement/all", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setAnnouncements',response.data);
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getAllYearAnnouncements({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/announcement/announcment_by_year/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setAnnouncements',response.data);
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getAnnouncmentInfo({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/announcement/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setAnnouncement',response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteAnnouncement({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .delete("/announcement/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteAnnouncement',payload);
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    addAnnouncement({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/announcement/add" , payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setAnnouncement',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    editAnnouncement({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .put("/announcement/" + payload.id,payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setAnnouncement',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
}
          