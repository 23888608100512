import axios from "axios";
import { Promise } from "core-js";
import userService from '../../helpers/userService';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const state = {
    studentsArr: [],
    id: null,
    student: null,
    attendenceArr: [],
    logins: []
};

export const mutations = {
  setStudents(state,payload){
    state.studentsArr = payload;
  },
  setStudentLogs(state,payload){
    state.logins = payload;
  },
  setUserAttendence(state,payload){
    state.attendenceArr = payload;
  },
  setStudent(state,payload){
    state.student = payload;
  },
  deleteStudent(state, id){
    let index = state.studentsArr.findIndex(element => element.id == id)
     state.studentsArr.splice(index, 1)
    },
    clearStudentsList(state){
      state.studentsArr = [];
    },
    clearState(state){
      state.studentsArr = [];
      state.id = null;
      state.student = null;
      state.attendenceArr = [];
      state.logins = [];
    },
    clearLogins(state){
      state.logins = [];
    },
}

export const getters = {
  allStudents(state){
    return state.studentsArr;
  },
  getStudent(state){
    return state.student;
  },
  getStudentLogs(state){
    return state.logins;
  },
  studentAttendence(state){
    return state.attendenceArr;
  },

}

export const actions = {
    async getStudentInfo({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/student/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setStudent',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clearState');
            await reject(error);
          });
      });
    },
    async getStudentLogins({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/admin/get_user_logins/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setStudentLogs',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await reject(error);
          });
      });
    },
    async resetLogins({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/admin/reset_user_logins/" + payload, payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('clearLogins')
            resolve(response.data);
          })
          .catch(async error => {
            await reject(error);
          });
      });
    },
    async getAttendanceInfo({commit},payload){
      return new Promise( async (resolve, reject) => {
        axios
          .get("/attendance/attendance/student/"+payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(async response => {
            //if the status code shows a successful request
            if (response.status == 200) {
              //store the user data in the store
              await commit("setUserAttendence", response.data);
              resolve(true);
            }
          })
          .catch(async error => {
            await commit('clearState');
            await reject(error);
          });
      });
    },
    async searchStudentByPhone({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/student/student_search/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            // commit('setStudent',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clearState');
            await reject(error);
          });
      });
    },
    async searchStudentByCode({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/student/get_student_by_code/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            // commit('setStudent',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clearState');
            await reject(error);
          });
      });
    },
    async updateStudentCredentials({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/student/update_student_username_and_password/?phone=${payload.phone}&username=${payload.username}&password=${payload.new_password}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clearState');
            await reject(error);
          });
      });
    },
    async getStudentList({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/student/all", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setStudents',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clearState');
            await reject(error);
          });
      });
    },
    async getSearchStudentList  ({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/student/search_student_by_name/${payload}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setStudents',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clearState');
            await reject(error);
          });
      });
    },
    async getStudentApprovalList({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/student/student/need_approval", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setStudents',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clearState');
            await reject(error);
          });
      });
    },
    async getStudentNoProfileList({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/student/student/need_to_compelete_profile", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setStudents',response.data);
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clearState');
            await reject(error);
          });
      });
    },
    async getOnlineAttendance({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/attendance/online_attendance", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            resolve(response.data);
          })
          .catch(async error => {
            await commit('clearState');
            await reject(error);
          });
      });
    },
    approveStudent({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/assistant/approve_stud/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('deleteStudent',payload);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    updateNewName({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/student/edit_name", payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    updateNewPhone({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post(`/student/edit_phone_number/${payload.stud_id}/${payload.phone}`, payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    updateNewParent1({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post(`/student/edit_parent_phone_number_one/${payload.stud_id}/${payload.phone}`, payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    updateNewParent2({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post(`/student/edit_parent_phone_number_two/${payload.stud_id}/${payload.phone}`, payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    updateNewBalance({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post(`/admin/update_balance/${payload.balance}/${payload.stud_id}`, payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteStudent({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .delete("/student/student/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('deleteStudent',payload);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    async clearList({commit}){
      await commit('clearStudentsList');
    },
    
}
