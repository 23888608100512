import axios from "axios";
import { Promise } from "core-js";
import userService from '../../helpers/userService';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const state = {
    assistantsArr: [],
    id: null,
    name: '',
    address: '',
    phone: '',
    assistant: null,
};

export const mutations = {
  setAssistants(state,payload){
    state.assistantsArr = payload;
  },
  setAssistant(state,payload){
    state.assistant = payload;
  },
  deleteAssistant(state, id){
    let index = state.assistantsArr.findIndex(element => element.id == id)
     state.assistantsArr.splice(index, 1)
    },
}

export const getters = {
  allAssistants(state){
    return state.assistantsArr;
  },
  getAssistant(state){
    return state.assistant;
  },
  

}

export const actions = {
    getAllAssistants({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/assistant/assistant", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setAssistants',response.data);
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    getAssistantInfo({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/assistant/assistant/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setAssistant',response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteAssistant({commit},payload){
      return new Promise((resolve, reject) => {
        axios
        .delete("/assistant/assistant/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteAssistant',payload);
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
      });
    },
    addAssistant({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post("/assistant/register_assistant" , payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setAssistant',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    editAssistant({commit},payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .put("/assistant/assistant/" + payload.id,payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setAssistant',response.body)
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
    
}
