import axios from "axios";
import { Promise } from "core-js";
import userService from '../../helpers/userService';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const state = {
    balanceArr: [],
    centerList: [],
    logs:[]
};

export const mutations = {
  setBalanceCodes(state,payload){
    state.balanceArr = payload;
  },
  setCenterList(state,payload){
    state.centerList = payload;
  },
  setLogs(state,payload){
    state.logs = payload;
  },
  deleteCode(state, id){
    let index = state.balanceArr.findIndex(element => element.id == id)
     state.balanceArr.splice(index, 1)
    },
  deleteCenterItem(state, id){
    let index = state.centerList.findIndex(element => element.id == id)
     state.centerList.splice(index, 1)
    },
    clear(state){
      state.balanceArr= [];
      state.centerList= [];
      state.logs= [];
    }
}

export const getters = {
  allBalanceCodes(state){
    return state.balanceArr;
  },
  allCenterList(state){
    return state.centerList;
  },
  allLogs(state){
    return state.logs;
  },
}

export const actions = {
    async getAllBalanceCodes({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/admin/get_files", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setBalanceCodes',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getAllCenterList({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/admin/codes/get_items", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setCenterList',response.data);
            resolve(true);
          })
          .catch(async error => {
            // await commit('clear');
            await reject(error);
          });
      });
    },
    async getBalanceLogs({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/student/codes/logs/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setLogs',response.data);
            resolve(true);
          })
          .catch(async error => {
            // await commit('clear');
            await reject(error);
          });
      });
    },
    async getStats({ commit }){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/admin/codes/report", {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setLogs',response.data);
            resolve(true);
          })
          .catch(async error => {
            await commit('clear');
            await reject(error);
          });
      });
    },
    async getPurchaseLogs({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get("/purchase/get_purchase_log/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            commit('setLogs',response.data);
            resolve(true);
          })
          .catch(async error => {
            // await commit('clear');
            await reject(error);
          });
      });
    },
    generate({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post(`/admin/generate_codes?number=${payload.number}&value=${payload.amount}`,{}, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    createCenter({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post(`/admin/codes/add_item?name=${payload.name}`,{}, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    redeemCode({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .post(`/student/codes/use_code?code=${payload.code}&dropdown_id=${payload.id}`,{}, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    checkCode({ commit },payload){
      return new Promise((resolve, reject) => {
        //Send get request to get all centers in db
        axios
          .get(`/admin/codes/logs/${payload}`,{}, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
          .then(response => {
            //if a response returned
            console.log(response.data)
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // downloadQrcodes({state},payload){
    //     return new Promise((resolve, reject) => {
    //         //Send get request to get all centers in db
    //         axios
    //           .get(`/admin/qrcode/download?time=${payload}`, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
    //           .then(response => {
    //             //if a response returned
    //             resolve(response);
    //           })
    //           .catch(error => {
    //             reject(error);
    //           });
    //       }); 
    // }
    deleteBalanceCode({commit},payload){
        axios
        .delete("/admin/codes/remove_file/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteCode',payload);
        })
        .catch(error => {
        });
    },
    deleteCenter({commit},payload){
        axios
        .delete("/admin/codes/delete_item/" + payload, {headers:{'Access-Control-Allow-Origin':'*',Authorization: `Bearer ${userService.methods.userToken()}`}})
        .then(response => {
          //if a response returned
          commit('deleteCenterItem',payload);
        })
        .catch(error => {
        });
    },
}
